:global .chargePage {
  background-color: white;
}
:global .chargePage .charging-car-gif {
  margin-top: 10vw;
  text-align: center;
  width: 80vw;
  height: 49vw;
  background: url('../../assets/car.jpeg') no-repeat;
  background-size: 100% 100%;
}
:global .chargePage .charge-hint {
  margin-top: 4vw;
  margin-left: 14vw;
  width: 74%;
  font-weight: bold;
}
:global .chargePage .addf1 {
  margin-top: 7vw;
  width: 100%;
}
:global .chargePage .addf {
  margin-top: 3vw;
  width: 100%;
}
:global .chargePage .addf-fee {
  width: 100%;
  text-align: center;
}
:global .chargePage .fee {
  width: 20vw;
  float: left;
  margin-left: 4vw;
  margin-top: 11vw;
  font-size: 6vw;
}
:global .chargePage .stop-btn1 {
  margin-top: 2vw;
  width: 30vw;
  height: 12vw;
  border-radius: 4vw;
  background-color: #4dc86f;
  color: white;
}
:global .chargePage .charge-title {
  font-size: 14px;
  padding: 30px 0 18px 0;
}
:global .chargePage .am-list-extra {
  text-align: left;
}
:global .chargePage .am-radio {
  padding: 0px;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding-left: 0px;
}
:global .charge-direct {
  margin-top: 22vw;
}
