/*do not import this file except components/style/index.less*/
.am-fade-enter,
.am-fade-appear {
  opacity: 0;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.am-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.am-fade-enter.am-fade-enter-active,
.am-fade-appear.am-fade-appear-active {
  animation-name: amFadeIn;
  animation-play-state: running;
}
.am-fade-leave.am-fade-leave-active {
  animation-name: amFadeOut;
  animation-play-state: running;
}
@keyframes amFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes amFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.am-slide-up-enter,
.am-slide-up-appear {
  transform: translate(0, 100%);
}
.am-slide-up-enter,
.am-slide-up-appear,
.am-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.am-slide-up-enter.am-slide-up-enter-active,
.am-slide-up-appear.am-slide-up-appear-active {
  animation-name: amSlideUpIn;
  animation-play-state: running;
}
.am-slide-up-leave.am-slide-up-leave-active {
  animation-name: amSlideUpOut;
  animation-play-state: running;
}
@keyframes amSlideUpIn {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes amSlideUpOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 100%);
  }
}
.am.am-zoom-enter,
.am.am-zoom-leave {
  display: block;
}
.am-zoom-enter,
.am-zoom-appear {
  opacity: 0;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.am-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.am-zoom-enter.am-zoom-enter-active,
.am-zoom-appear.am-zoom-appear-active {
  animation-name: amZoomIn;
  animation-play-state: running;
}
.am-zoom-leave.am-zoom-leave-active {
  animation-name: amZoomOut;
  animation-play-state: running;
}
@keyframes amZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes amZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.am-slide-down-enter,
.am-slide-down-appear {
  transform: translate(0, -100%);
}
.am-slide-down-enter,
.am-slide-down-appear,
.am-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.am-slide-down-enter.am-slide-down-enter-active,
.am-slide-down-appear.am-slide-down-appear-active {
  animation-name: amSlideDownIn;
  animation-play-state: running;
}
.am-slide-down-leave.am-slide-down-leave-active {
  animation-name: amSlideDownOut;
  animation-play-state: running;
}
@keyframes amSlideDownIn {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes amSlideDownOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -100%);
  }
}
*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  background-color: #f5f5f9;
  font-size: 14px;
}
*[contenteditable] {
  -webkit-user-select: auto !important;
}
*:focus {
  outline: none;
}
a {
  background: transparent;
  text-decoration: none;
  outline: none;
}
