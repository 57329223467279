.am-navbar {
  display: flex;
  align-items: center;
  height: 45px;
  background-color: #108ee9;
  color: #fff;
}
.am-navbar-left,
.am-navbar-title,
.am-navbar-right {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.am-navbar-left {
  padding-left: 15px;
  font-size: 16px;
}
.am-navbar-left-icon {
  margin-right: 5px;
  display: inherit;
}
.am-navbar-title {
  justify-content: center;
  font-size: 18px;
  white-space: nowrap;
}
.am-navbar-right {
  justify-content: flex-end;
  font-size: 16px;
  margin-right: 15px;
}
.am-navbar-light {
  background-color: #fff;
  color: #108ee9;
}
.am-navbar-light .am-navbar-title {
  color: #000;
}
