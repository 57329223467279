#vehiclePlateKeyboard .vehicle-plate-keyboard-container {
  position: fixed;
  min-height: 73.6vw;
  background-color: #e8e9eb;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 1.6vw 0px rgba(0, 0, 0, 0.2);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container *:not(input, textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .confirm {
  height: 11.733vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .confirm p {
  font-size: 3.733vw;
  color: #333333;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .confirm:active {
  opacity: 0.5;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard {
  height: 61.867vw;
  background-color: #e8e9eb;
  display: flex;
  justify-content: center;
  align-items: center;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container {
  display: flex;
  flex-direction: column;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .keyboard-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .keyboard-cell {
  display: flex;
  background-color: white;
  border-radius: 1.067vw;
  justify-content: center;
  align-items: center;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .keyboard-cell .cell-text {
  font-size: 4.267vw;
  color: #333333;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .keyboard-cell:active {
  background-color: #b3b7bf;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .normal-cell {
  width: 8.533vw;
  height: 9.6vw;
  margin: 1.067vw 0.667vw;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .province-cell {
  width: 10.667vw;
  height: 11.733vw;
  margin: 1.333vw 0.8vw;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .character-cell {
  width: 14.4vw;
  height: 9.6vw;
  margin: 1.067vw 0.533vw;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .cell-disabled {
  opacity: 0.4;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .cell-disabled:active {
  background-color: white;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .back-btn {
  width: 19.733vw;
  height: 9.6vw;
  margin-left: 0.533vw;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container .keyboard .keyboard-container .back-btn .back-btn-svg {
  width: 6.4vw;
  height: 4.8vw;
  line-height: 4.8vw;
  padding-right: 1.6vw;
  font-size: 6.4vw;
}
