.am-icon {
  fill: currentColor;
  background-size: cover;
  width: 22px;
  height: 22px;
}
.am-icon-xxs {
  width: 15px;
  height: 15px;
}
.am-icon-xs {
  width: 18px;
  height: 18px;
}
.am-icon-sm {
  width: 21px;
  height: 21px;
}
.am-icon-md {
  width: 22px;
  height: 22px;
}
.am-icon-lg {
  width: 36px;
  height: 36px;
}
.am-icon-loading {
  animation: cirle-anim 1s linear infinite;
}
@keyframes cirle-anim {
  100% {
    transform: rotate(360deg);
  }
}
