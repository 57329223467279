:global .monitorPage {
  width: 100%;
  font-size: 3.4vw;
}
:global .monitorPage .col-item {
  width: 100%;
  margin-top: 2vw;
}
:global .monitorPage .col-item-1 {
  width: 100%;
  margin-top: 1vw;
  text-align: center;
}
:global .monitorPage .col-item-2 {
  width: 90%;
  margin-top: 1vw;
}
:global .monitorPage .back-btn {
  margin-top: 2vw;
  margin-left: 4vw;
  width: 15.5vw;
  height: 8vw;
  font-size: 3.6vw;
  padding-top: 1.8vw;
  padding-left: 6vw;
  background-color: #4dc86f;
  background-image: url('../../assets/left.png');
  background-repeat: no-repeat;
  background-position: -0.4vw 0.6vw;
  background-size: 7vw 6.8vw;
  color: white;
}
:global .monitorPage .row-left-item {
  padding-left: 4vw;
}
:global .monitorPage .row-right-item {
  padding-right: 4vw;
  text-align: right;
}
:global .monitorPage .charging-now-gif {
  margin-top: 2vw;
  text-align: center;
  width: 70vw;
  height: 51vw;
  background: url('../../assets/charging-now.gif') no-repeat;
  background-size: 100% 100%;
}
:global .monitorPage .gray-line {
  margin-left: 4vw;
  margin-right: 4vw;
  height: 0.7vw;
  background: url('../../assets/grayline1.png') no-repeat;
  background-size: 100% 100%;
}
:global .monitorPage .stop-btn {
  margin-top: 2vw;
  width: 70vw;
  height: 12vw;
  border-radius: 4vw;
  background-color: #4dc86f;
  color: white;
}
:global .monitorPage .done-btn {
  margin-top: 2vw;
  width: 70vw;
  height: 12vw;
  /*      font-size: 5vw;*/
  border-radius: 4vw;
  background-color: #c01e2f;
  color: white;
}
:global .monitorPage .monitor-hint {
  margin-top: 2vw;
  font-weight: bold;
}
