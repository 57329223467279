.name_content .content_card {
  margin: 0;
  background-color: #8bd;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.name_content .content_card .avatar {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 2rem;
  margin: 0.25rem auto 0.1rem;
  background: #fff;
  box-shadow: 0 0 2px gray;
}
.name_content .content_card .content_name {
  font-family: PingFangSC-Medium;
  font-size: 0.28rem;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 0.28rem;
  margin: 0.21rem auto;
  text-shadow: 0 0 3px gray;
}
.name_content .content_card .wallet .hint {
  font-family: PingFangSC-Medium;
  font-size: 0.4rem;
  color: white;
  text-shadow: 0 0 3px gray;
  letter-spacing: 3px;
  text-align: center;
}
.name_content .content_card .wallet .balance {
  font-family: PingFangSC-Medium;
  color: white;
  text-shadow: 0 0 3px gray;
  font-size: 0.75rem;
  text-align: center;
}
.name_content .content_namecard_setting {
  background: url(/images/me/setting.png) no-repeat center center;
  background-size: cover;
  width: 0.32rem;
  height: 0.32rem;
  background-size: 0.32rem 0.32rem;
  padding: 0.5rem 0.3rem 0.5rem 0.5rem;
}
