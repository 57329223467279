.am-tabs {
  box-sizing: border-box;
  display: flex;
  flex: 1 1;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.am-tabs * {
  box-sizing: border-box;
}
.am-tabs-content-wrap {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  min-height: 0;
}
.am-tabs-content-wrap-animated {
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: transform, left, top;
}
.am-tabs-pane-wrap {
  width: 100%;
  flex-shrink: 0;
  overflow-y: auto;
}
.am-tabs-tab-bar-wrap {
  flex-shrink: 0;
}
.am-tabs-horizontal .am-tabs-pane-wrap-active {
  height: auto;
}
.am-tabs-horizontal .am-tabs-pane-wrap-inactive {
  height: 0;
  overflow: visible;
}
.am-tabs-vertical .am-tabs-content-wrap {
  flex-direction: column;
}
.am-tabs-vertical .am-tabs-tab-bar-wrap {
  height: 100%;
}
.am-tabs-vertical .am-tabs-pane-wrap {
  height: 100%;
}
.am-tabs-vertical .am-tabs-pane-wrap-active {
  overflow: auto;
}
.am-tabs-vertical .am-tabs-pane-wrap-inactive {
  overflow: hidden;
}
.am-tabs-top,
.am-tabs-bottom {
  flex-direction: column;
}
.am-tabs-left,
.am-tabs-right {
  flex-direction: row;
}
.am-tabs-default-bar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1;
}
.am-tabs-default-bar-tab {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  height: 43.5px;
  line-height: 43.5px;
}
.am-tabs-default-bar-tab .am-badge .am-badge-text {
  top: -13px;
  transform: translateX(-5px);
}
.am-tabs-default-bar-tab .am-badge .am-badge-dot {
  top: -6px;
  transform: translateX(0);
}
.am-tabs-default-bar-tab-active {
  color: #108ee9;
}
.am-tabs-default-bar-underline {
  position: absolute;
  border: 1px #108ee9 solid;
  transform: translate3d(0, 0, 0);
}
.am-tabs-default-bar-animated .am-tabs-default-bar-content {
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: transform;
}
.am-tabs-default-bar-animated .am-tabs-default-bar-underline {
  transition: top 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), color 0.3s cubic-bezier(0.35, 0, 0.25, 1), width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: top, left, width, color;
}
.am-tabs-default-bar-top,
.am-tabs-default-bar-bottom {
  flex-direction: row;
}
.am-tabs-default-bar-top .am-tabs-default-bar-content,
.am-tabs-default-bar-bottom .am-tabs-default-bar-content {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.am-tabs-default-bar-top .am-tabs-default-bar-prevpage,
.am-tabs-default-bar-bottom .am-tabs-default-bar-prevpage {
  pointer-events: none;
  position: absolute;
  top: 0;
  display: block;
  width: 59px;
  height: 100%;
  content: ' ';
  z-index: 999;
  left: 0;
  background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}
.am-tabs-default-bar-top .am-tabs-default-bar-nextpage,
.am-tabs-default-bar-bottom .am-tabs-default-bar-nextpage {
  pointer-events: none;
  position: absolute;
  top: 0;
  display: block;
  width: 59px;
  height: 100%;
  content: ' ';
  z-index: 999;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
}
.am-tabs-default-bar-top .am-tabs-default-bar-tab,
.am-tabs-default-bar-bottom .am-tabs-default-bar-tab {
  padding: 8px 0;
}
.am-tabs-default-bar-top .am-tabs-default-bar-underline,
.am-tabs-default-bar-bottom .am-tabs-default-bar-underline {
  bottom: 0;
}
.am-tabs-default-bar-top .am-tabs-default-bar-tab {
  border-bottom: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-tabs-default-bar-top .am-tabs-default-bar-tab {
    border-bottom: none;
  }
  html:not([data-scale]) .am-tabs-default-bar-top .am-tabs-default-bar-tab::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 100%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-tabs-default-bar-top .am-tabs-default-bar-tab::after {
    transform: scaleY(0.33);
  }
}
.am-tabs-default-bar-bottom .am-tabs-default-bar-tab {
  border-top: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-tabs-default-bar-bottom .am-tabs-default-bar-tab {
    border-top: none;
  }
  html:not([data-scale]) .am-tabs-default-bar-bottom .am-tabs-default-bar-tab::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    transform-origin: 50% 50%;
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-tabs-default-bar-bottom .am-tabs-default-bar-tab::before {
    transform: scaleY(0.33);
  }
}
.am-tabs-default-bar-left,
.am-tabs-default-bar-right {
  flex-direction: column;
}
.am-tabs-default-bar-left .am-tabs-default-bar-content,
.am-tabs-default-bar-right .am-tabs-default-bar-content {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.am-tabs-default-bar-left .am-tabs-default-bar-tab,
.am-tabs-default-bar-right .am-tabs-default-bar-tab {
  padding: 0 8px;
}
.am-tabs-default-bar-left .am-tabs-default-bar-underline {
  right: 0;
}
.am-tabs-default-bar-left .am-tabs-default-bar-tab {
  border-right: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-tabs-default-bar-left .am-tabs-default-bar-tab {
    border-right: none;
  }
  html:not([data-scale]) .am-tabs-default-bar-left .am-tabs-default-bar-tab::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 1PX;
    height: 100%;
    background: #ddd;
    transform-origin: 100% 50%;
    transform: scaleX(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-tabs-default-bar-left .am-tabs-default-bar-tab::after {
    transform: scaleX(0.33);
  }
}
.am-tabs-default-bar-right .am-tabs-default-bar-underline {
  left: 0;
}
.am-tabs-default-bar-right .am-tabs-default-bar-tab {
  border-left: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-tabs-default-bar-right .am-tabs-default-bar-tab {
    border-left: none;
  }
  html:not([data-scale]) .am-tabs-default-bar-right .am-tabs-default-bar-tab::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 1PX;
    height: 100%;
    transform-origin: 100% 50%;
    transform: scaleX(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-tabs-default-bar-right .am-tabs-default-bar-tab::before {
    transform: scaleX(0.33);
  }
}
