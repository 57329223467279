.my {
  width: 100%;
  height: 100%;
  background: #fff;
}
.myHeader {
  height: 153px;
  position: relative;
}
.myHeaderLogin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 92px;
  height: 92px;
  background: #e60f0d;
  border-radius: 50%;
  text-align: center;
  line-height: 92px;
}
.myHeaderLogin a {
  color: #fff;
  font-size: 16px;
}
.content_me {
  /*padding-top: .3rem;*/
}
.content_me .service_info {
  background: #fff;
  width: 6.9rem;
  margin-left: 0.3rem;
  padding: 0.3rem 0 0.35rem 0;
  border-radius: 8px;
  margin-top: 0.2rem;
}
.content_me .service_info .list-item {
  padding: 0.1rem;
}
.content_me .service_info .list-item .item-text i {
  margin-right: 0.1rem;
}
.charging {
  color: #4dc86f;
  margin-top: 0.3rem;
  text-align: center;
  font-size: 1.4em;
  /*font-weight: bold;*/
  text-decoration: underline;
}
