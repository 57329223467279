.am-button {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  font-size: 18px;
  height: 47px;
  line-height: 47px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #000;
  background-color: #fff;
  border: 1PX solid #ddd;
  border-radius: 5px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button {
    position: relative;
    border: none;
  }
  html:not([data-scale]) .am-button::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid #ddd;
    border-radius: 10px;
    transform-origin: 0 0;
    transform: scale(0.5);
    box-sizing: border-box;
    pointer-events: none;
  }
}
.am-button-borderfix:before {
  transform: scale(0.49) !important;
}
.am-button.am-button-active {
  background-color: #ddd;
}
.am-button.am-button-disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 0.6;
}
.am-button-primary {
  color: #fff;
  background-color: #108ee9;
  border: 1PX solid #108ee9;
  border-radius: 5px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button-primary {
    position: relative;
    border: none;
  }
  html:not([data-scale]) .am-button-primary::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid #108ee9;
    border-radius: 10px;
    transform-origin: 0 0;
    transform: scale(0.5);
    box-sizing: border-box;
    pointer-events: none;
  }
}
.am-button-primary.am-button-active {
  color: rgba(255, 255, 255, 0.3);
  background-color: #0e80d2;
}
.am-button-primary.am-button-disabled {
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.4;
}
.am-button-ghost {
  color: #108ee9;
  background-color: transparent;
  border: 1PX solid #108ee9;
  border-radius: 5px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button-ghost {
    position: relative;
    border: none;
  }
  html:not([data-scale]) .am-button-ghost::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid #108ee9;
    border-radius: 10px;
    transform-origin: 0 0;
    transform: scale(0.5);
    box-sizing: border-box;
    pointer-events: none;
  }
}
.am-button-ghost.am-button-active {
  color: rgba(16, 142, 233, 0.6);
  background-color: transparent;
  border: 1PX solid rgba(16, 142, 233, 0.6);
  border-radius: 5px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button-ghost.am-button-active {
    position: relative;
    border: none;
  }
  html:not([data-scale]) .am-button-ghost.am-button-active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid rgba(16, 142, 233, 0.6);
    border-radius: 10px;
    transform-origin: 0 0;
    transform: scale(0.5);
    box-sizing: border-box;
    pointer-events: none;
  }
}
.am-button-ghost.am-button-disabled {
  color: rgba(0, 0, 0, 0.1);
  border: 1PX solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  opacity: 1;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button-ghost.am-button-disabled {
    position: relative;
    border: none;
  }
  html:not([data-scale]) .am-button-ghost.am-button-disabled::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transform-origin: 0 0;
    transform: scale(0.5);
    box-sizing: border-box;
    pointer-events: none;
  }
}
.am-button-warning {
  color: #fff;
  background-color: #e94f4f;
}
.am-button-warning.am-button-active {
  color: rgba(255, 255, 255, 0.3);
  background-color: #d24747;
}
.am-button-warning.am-button-disabled {
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.4;
}
.am-button-inline {
  display: inline-block;
  padding: 0 15px;
}
.am-button-inline.am-button-icon {
  display: inline-flex;
}
.am-button-small {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
}
.am-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.am-button > .am-button-icon {
  margin-right: 0.5em;
}
