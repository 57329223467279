.am-toast {
  position: fixed;
  width: 100%;
  z-index: 1999;
  font-size: 14px;
  text-align: center;
}
.am-toast > span {
  max-width: 50%;
}
.am-toast.am-toast-mask {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  transform: translateZ(1px);
}
.am-toast.am-toast-nomask {
  position: fixed;
  max-width: 50%;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translateZ(1px);
}
.am-toast.am-toast-nomask .am-toast-notice {
  transform: translateX(-50%) translateY(-50%);
}
.am-toast-notice-content .am-toast-text {
  min-width: 60px;
  border-radius: 3px;
  color: #fff;
  background-color: rgba(58, 58, 58, 0.9);
  line-height: 1.5;
  padding: 9px 15px;
}
.am-toast-notice-content .am-toast-text.am-toast-text-icon {
  border-radius: 5px;
  padding: 15px 15px;
}
.am-toast-notice-content .am-toast-text.am-toast-text-icon .am-toast-text-info {
  margin-top: 6px;
}
