.am-radio {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
}
.am-radio-inner {
  position: absolute;
  right: 0;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  transform: rotate(0deg);
}
.am-radio-inner:after {
  position: absolute;
  display: none;
  top: -2.5px;
  right: 5px;
  z-index: 999;
  width: 7px;
  height: 14px;
  border-style: solid;
  border-width: 0 1.5px 1.5px 0;
  content: '\0020';
  transform: rotate(45deg);
}
.am-radio-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 0 none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.am-radio.am-radio-checked .am-radio-inner {
  border-width: 0;
}
.am-radio.am-radio-checked .am-radio-inner:after {
  display: block;
  border-color: #108ee9;
}
.am-radio.am-radio-disabled.am-radio-checked .am-radio-inner:after {
  display: block;
  border-color: #bbb;
}
.am-list .am-list-item.am-radio-item .am-list-line .am-list-extra {
  flex: 0 1;
}
.am-list .am-list-item.am-radio-item .am-list-line .am-list-extra .am-radio {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 44px;
  overflow: visible;
}
.am-list .am-list-item.am-radio-item .am-list-line .am-list-extra .am-radio-inner {
  right: 15px;
  top: 15px;
}
.am-list .am-list-item.am-radio-item.am-radio-item-disabled .am-list-content {
  color: #bbb;
}
